import { CdkTrapFocus } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'ods-popup',
  standalone: true,
  imports: [CommonModule, CdkTrapFocus],
  template: `<div class="relative w-fit">
    <button
      [ngClass]="[twMerge('w-fit outline-2 outline-offset-2 outline-focus', buttonClass)]"
      (click)="handleButtonClick()"
      [attr.aria-expanded]="isPopupOpen"
      aria-haspopup="true"
      [attr.aria-label]="label"
      data-test-id="popup-button"
      #button
    >
      <ng-content select="[button-content]" />
    </button>
    <ul
      *ngIf="isPopupOpen"
      class="absolute max-h-120 min-w-44 max-w-80 animate-fadeIn overflow-y-auto rounded shadow-lg shadow-grayborder focus:outline-none"
      [ngClass]="alignTo === 'left' ? 'right-0' : 'left-0'"
      role="dialog"
      aria-modal="true"
      tabIndex="-1"
      cdkTrapFocus
      #popupList
    >
      <ng-content />
    </ul>
  </div>`,
})
export class PopupComponent {
  @Input() alignTo: 'left' | 'right' = 'left';
  @Input() label: string = '';
  @Input() buttonClass: string = '';

  isPopupOpen: boolean = false;
  readonly twMerge = twMerge;

  @ViewChild('button') buttonRef: ElementRef<HTMLButtonElement>;
  @ViewChild('popupList') popupListRef: ElementRef<HTMLUListElement>;

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(e: KeyboardEvent): void {
    if (this.isPopupClosed()) return;
    if (this.isEscapeKey(e)) this.closePopupAndFocusButton();
  }

  @HostListener('document:click', ['$event'])
  onClickHandler(e: MouseEvent): void {
    if (this.isPopupClosed()) return;
    if (!this.buttonRef.nativeElement.contains(e.target as HTMLElement)) {
      this.closePopupAndFocusButton();
    }
  }

  handleButtonClick(): void {
    this.togglePopup();
    if (this.isPopupOpen) this.focusList();
  }

  focusList(): void {
    setTimeout(() => this.popupListRef.nativeElement.focus());
  }

  togglePopup(): void {
    this.isPopupOpen = !this.isPopupOpen;
  }

  closePopupAndFocusButton(): void {
    this.isPopupOpen = false;
    this.buttonRef.nativeElement.focus();
  }

  isEscapeKey(e: KeyboardEvent): boolean {
    return e.key === 'Escape';
  }

  isPopupClosed(): boolean {
    return !this.isPopupOpen;
  }
}
